import { all } from 'redux-saga/effects';

import {
  loginSuccess,
  signupSuccess,
  logoutSuccess,
  profileSuccess,
  profileFailure,
  sendToChannelError,
} from './profile/sagas';
import { listFailure } from './users/sagas';
import { notify } from './notify/sagas';

export default function* rootSaga(history, api) {
  yield all([
    loginSuccess(history, api),
    logoutSuccess(history, api),
    profileSuccess(history),
    listFailure(history),
    signupSuccess(history),
    profileFailure(history),
    sendToChannelError(),
    notify(),
  ]);
}
