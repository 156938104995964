import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { user } from '../../redux/profile/selectors';
import Credentials from './Credentials';

const selector = createStructuredSelector({
  user,
});

const actions = null;

export default connect(
  selector,
  actions,
)(Credentials);
