import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import classnames from './FBConnected.module.scss';

export default class FBConnected extends Component {
  constructor(props) {
    super(props);

    this.connect = this.connect.bind(this);
    this.showToken = this.showToken.bind(this);
  }

  connect() {
    const { connectFB } = this.props;
    connectFB();
  }

  showToken(e) {
    e.preventDefault();
    const {
      user: { fbData },
    } = this.props;
    Modal.info({
      title: 'FB access token',
      content: <div>{fbData.accessToken}</div>,
      onOk() {},
    });
  }

  render() {
    const {
      user: { fbData },
    } = this.props;
    if (fbData && fbData.id) {
      return (
        <div className={classnames.fbContainer}>
          <img alt="" src={fbData.picture.data.url} />
          <div>
            <p>{fbData.name}</p>
            <p>{fbData.email}</p>
            <a href="/" onClick={this.showToken}>
              Show access token
            </a>
          </div>
        </div>
      );
    }
    return (
      <Button onClick={this.connect} type="primary">
        Connect Facebook
      </Button>
    );
  }
}
