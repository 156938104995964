import React, { Component } from 'react';
import classnames from './HomePage.module.scss';

export default class HomePage extends Component {
  render() {
    return (
      <div className={classnames.container}>
        <br />
        <br />
        <h3>Home page</h3>
      </div>
    );
  }
}
