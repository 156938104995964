import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Table, Button } from 'antd';
import classnames from './ProfilePage.module.scss';

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Username',
    dataIndex: 'username',
  },
];

export default class ProfilePage extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  render() {
    const { user, fbToChannel, fbFromChannel } = this.props;

    return (
      <div className={classnames.container}>
        <Card title="My profile" className={classnames.card}>
          <div className={classnames.actionBtn}>
            <Button type="primary" onClick={fbToChannel}>
              Send FB data to channel
            </Button>
            <Button type="secondary" onClick={fbFromChannel}>
              Show FB data from channel
            </Button>
          </div>
          <Table
            rowKey="email"
            columns={columns}
            className={classnames.table}
            dataSource={[user]}
            pagination={false}
          />
        </Card>
      </div>
    );
  }
}
