import { takeEvery } from 'redux-saga/effects';
import { PROFILE_FAILURE, FB_TO_CHANNEL_SUCCESS } from '../profile/actions';
import { notification } from 'antd';

const toString = obj =>
  Object.entries(obj)
    .map(([key, val]) => `${key}: ${val}`)
    .join(', ');

export function* notify() {
  yield takeEvery('*', function(action) {
    if (action.type === FB_TO_CHANNEL_SUCCESS) {
      if (action.data && action.data.getFbDataError) {
        return notification.error(
          {
            message: 'Retrieving data error',
            description: 'Please try to reconnect Facebook Account',
          },
          7,
        );
      }
    }

    if (action.error && action.type !== PROFILE_FAILURE)
      notification.error(
        {
          message: action.error.code,
          description: action.error.message || toString(action.error.fields),
        },
        7,
      );
  });
}
