import Base from './base';

export default class Users extends Base {
  list(query) {
    return this.apiClient.get('users', query);
  }

  show(id) {
    return this.apiClient.get(`users/${id}`);
  }

  updateProfile(data) {
    return this.apiClient.put(`users`, { data });
  }

  connectFB() {
    return this.apiClient.get(`auth/facebook`);
  }

  fbToChannel() {
    return this.apiClient.get(`profile/fb-to-channel`);
  }

  fbFromChannel() {
    return this.apiClient.get(`profile/fb-from-channel`);
  }
}
