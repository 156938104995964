import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { user } from '../../redux/profile/selectors';
import { fbToChannel, fbFromChannel } from '../../redux/profile/actions';

import ProfilePage from './ProfilePage';

const selector = createStructuredSelector({
  user,
});

const actions = { fbToChannel, fbFromChannel };

export { ProfilePage };

export default connect(
  selector,
  actions,
)(ProfilePage);
