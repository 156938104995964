import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { update, setFBData, connectFB } from '../../redux/profile/actions';
import { user, fbData } from '../../redux/profile/selectors';
import FBConnected from './FBConnected';

const selector = createStructuredSelector({
  user,
  fbData,
});

const actions = { update, setFBData, connectFB };

export default connect(
  selector,
  actions,
)(FBConnected);
