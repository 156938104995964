import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import classes from './MainLayout.module.scss';

const { Content, Footer } = Layout;

export default class MainLayout extends Component {
  static propTypes = exact({
    children: PropTypes.element,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.object,

    init: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  });

  componentWillMount() {
    this.props.init();
  }

  render() {
    const { user, pathname, logout, children } = this.props;
    const isHomePage = pathname === '/';
    return (
      <Layout className={classes.root}>
        <MainHeader
          user={user}
          pathname={pathname}
          logout={logout}
          isHomePage={isHomePage}
        />
        <Content className={`${classes.content} ${isHomePage && classes.home}`}>
          {children}
        </Content>
        <Footer theme="dark" className={classes.footer}>
          <ul>
            <li>
              <Link to={ROUTES.HOME}>{LINK_LABELS.HOME}</Link>
            </li>
            <li>
              <Link to={ROUTES.PROFILE}>{LINK_LABELS.PROFILE}</Link>
            </li>
            <li>
              <Link to={ROUTES.HOME}>{LINK_LABELS.ABOUT_US}</Link>
            </li>
            <li>
              <Link to={ROUTES.HOME}>{LINK_LABELS.CONTACT_US}</Link>
            </li>
          </ul>
        </Footer>
      </Layout>
    );
  }
}
