import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import classnames from './Credentials.module.scss';

export default class Credentials extends Component {
  constructor(props) {
    super(props);

    this.showCredentials = this.showCredentials.bind(this);
  }

  showCredentials(e) {
    e.preventDefault();
    const {
      user: { credential },
    } = this.props;
    const offerData = credential.offer.attributes;

    Modal.info({
      title: 'Openssi credentials',
      content: (
        <div className={classnames.credentialPopup}>
          <div>
            <p>
              <b>Offer:</b>&nbsp;
            </p>
            <div className={classnames.offer}>
              {Object.keys(offerData).map(key => {
                let val =
                  'object' === typeof offerData[key]
                    ? JSON.stringify(offerData[key])
                    : offerData[key];
                if (val.length > 800) val = val.slice(0, 800) + ' ... ';
                return (
                  <div key={key}>
                    <b>
                      <i>{key}</i>
                    </b>
                    :&nbsp;{val}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ),
      width: '70%',
      onOk() {},
    });
  }

  render() {
    const {
      user: { credential },
    } = this.props;
    if (credential && credential.id) {
      return (
        <div className={classnames.credentialsContainer}>
          <Button type="primary" onClick={this.showCredentials}>
            Show credential
          </Button>
        </div>
      );
    }
    return null;
  }
}
