import React from 'react';
import { Modal } from 'antd';
import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.PROFILE_REQUEST:
      case actions.LOGIN_REQUEST:
      case actions.LOGOUT_REQUEST:
      case actions.SIGNUP_REQUEST:
      case actions.FB_TO_CHANNEL_REQUEST:
      case actions.FB_FROM_CHANNEL_REQUEST:
        return true;
      case actions.PROFILE_SUCCESS:
      case actions.PROFILE_FAILURE:
      case actions.LOGIN_SUCCESS:
      case actions.LOGIN_FAILURE:
      case actions.LOGOUT_SUCCESS:
      case actions.LOGOUT_FAILURE:
      case actions.SIGNUP_SUCCESS:
      case actions.SIGNUP_FAILURE:
      case actions.FB_TO_CHANNEL_SUCCESS:
      case actions.FB_TO_CHANNEL_FAILURE:
      case actions.FB_FROM_CHANNEL_SUCCESS:
      case actions.FB_FROM_CHANNEL_FAILURE:
        return false;
      default:
        return state;
    }
  },
  user(state = null, action) {
    switch (action.type) {
      case actions.PROFILE_SUCCESS:
        return action.data;
      case actions.LOGOUT_SUCCESS:
        return null;
      default:
        return state;
    }
  },
  isConfirmed(state = false, action) {
    switch (action.type) {
      case actions.CONFIRM_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  isChangedPwd(state = false, action) {
    switch (action.type) {
      case actions.RESET_PASSWORD_REQUEST:
      case actions.RESET_PASSWORD_FAILURE:
        return false;
      case actions.RESET_PASSWORD_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  isRecoveryRequested(state = false, action) {
    switch (action.type) {
      case actions.PASSWORD_RECOVERY_REQUEST:
      case actions.PASSWORD_RECOVERY_FAILURE:
        return false;
      case actions.PASSWORD_RECOVERY_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.LOGIN_FAILURE:
      case actions.LOGOUT_FAILURE:
      case actions.PASSWORD_RECOVERY_FAILURE:
      case actions.RESET_PASSWORD_FAILURE:
        return action.error;
      default:
        return state;
    }
  },
  successfullyRegistered(state = false, action) {
    switch (action.type) {
      case actions.SIGNUP_SUCCESS:
        return true;
      case actions.RESET_SUCCESS_STATE:
        return false;
      default:
        return state;
    }
  },
  fbData(state = {}, action) {
    switch (action.type) {
      case actions.SET_FB_DATA:
        return action.data;
      default:
        return state;
    }
  },
  connectingFB(state = {}, action) {
    switch (action.type) {
      case actions.CONNECT_FB_SUCCESS:
        window.open(action.data.authUrl);
        return action.data;
      default:
        return state;
    }
  },
  fbDataFromChannel(state = {}, action) {
    switch (action.type) {
      case actions.FB_FROM_CHANNEL_SUCCESS:
        Modal.info({
          title: 'Data from channel',
          content: (
            <div>
              {Object.keys(action.data).map(key => {
                let val =
                  'object' === typeof action.data[key]
                    ? JSON.stringify(action.data[key])
                    : action.data[key];
                if (val.length > 800) val = val.slice(0, 800) + ' ... ';
                return (
                  <div key={key}>
                    <b>
                      <i>{key}</i>
                    </b>
                    :&nbsp;{val}
                  </div>
                );
              })}
            </div>
          ),
          width: '70%',
          onOk() {},
        });
        return action.data;
      default:
        return state;
    }
  },
});
